<template>
    <div>
        <div v-if="!fixedLanguages" class="w-50 pr-1 mb-4">
            <LabelForm>{{ $t("shared.localized-content.language") }}</LabelForm>
            <div class="flex">
                <select v-model="selectedLanguage" class="w-full border-grey-light input-modal">
                    <option :key="language.acronym" :value="language.acronym" v-for="language in missignLanguages">
                        {{ language.name }}
                    </option>
                </select>
                <button :disabled="selectedLanguage === null" class="ml-3 sk-btn sk-btn-primary rounded-lg" @click.stop="addLanguage">{{ $t('shared.add') }}</button>
            </div>
        </div>

        <div class="flex flex-wrap">
            <div v-for="(contentLanguage, index) in internalLanguages" :key="contentLanguage.language"
                class="w-full lg:w-5/12  relative mt-6 lg:mr-6 p-6 border border-grey rounded"
            >
                <div class="flex justify-between items-center">
                    <h5 class="mb-0">{{ getLanguageName(contentLanguage.language) }}</h5>

                    <button v-if="!fixedLanguages" class="ml-4 sk-btn sk-btn-small btn-border sk-btn-primary rounded-lg"
                        @click.stop="deleteLanguage(contentLanguage.language)"
                    >
                        <feather-icon icon="TrashIcon" class="align-middle" svgClasses="text-primary w-4" />
                    </button>
                </div>

                <div v-for="field in fieldsDefinition" :key="field.name">
                    <LabelForm>{{ field.label }}</LabelForm>

                    <vs-input v-if="field.type === 'text'" class="w-full border-grey-light"
                        v-model="internalLanguages[index][field.name]" @input="sync" />

                    <textarea v-if="field.type === 'textarea'"
                        class="w-full h-32 rounded resize-none border-grey-light p-2"
                        v-model="internalLanguages[index][field.name]" @input="sync" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import LabelForm from '@/modules/Shared/Components/form/LabelForm'

export default {
    name: 'LocalizedContent',
    components: {
        LabelForm,
    },
    props: {
        value: {
            type: Array,
            default: () => []
        },
        fieldsDefinition: {
            type: Array,
            default: () => []
        },
        languages: {
            type: Array,
            default: () => []
        },
        fixedLanguages: {
            type: Boolean,
            default: false
        }
    },
    watch: {
        value: {
            immediate: true,
            handler: function () {
                this.internalLanguages = this.value
            }
        }
    },
    computed: {
        ...mapGetters(['spotlioLanguages']),
        missignLanguages() {
            let currentLangAcronyms = this.internalLanguages.map((l) => l.language)
            const languages = this.spotlioLanguages.filter(lang => !currentLangAcronyms.includes(lang.acronym))

            return this.languages.length === 0
                ? languages
                : languages.filter(lang => this.languages.includes(lang.acronym))
        }
    },
    data() {
        return {
            selectedLanguage: null,
            internalLanguages: []
        }
    },
    methods: {
        createEmptyLanguage(selectedLanguage) {
            let language = { language: selectedLanguage }
            this.fieldsDefinition.map((definition) => language[definition.name] = null)
            return language
        },
        addLanguage() {
            this.internalLanguages.push(this.createEmptyLanguage(this.selectedLanguage))
            this.selectedLanguage = null
            this.sync()
        },
        deleteLanguage(acronym) {
            let index = this.internalLanguages.findIndex((l) => l.language === acronym)
            this.internalLanguages.splice(index, 1)
            this.sync()
        },
        getLanguageName(acronymo) {
            return this.spotlioLanguages.find(l => l.acronym === acronymo).name
        },
        sync() {
            this.$emit('input', this.internalLanguages)
        },
    }
}
</script>
