<template>
    <div>
        <div class="mt-3 mb-10 vx-row">
            <div v-if="actions.includes('create')" class="w-full pt-2 text-right vx-col">
                <vs-button
                    class="rounded-lg xs:w-full sm:w-auto"
                    :to="{ name: `${routeConfigs.prefix}-create` }" >
                    {{ $t("shared.add") }}
                </vs-button>
            </div>
        </div>
        <div>
            <Datatable
                :config="datatableConfig"
                :elements="zones"
                :stripe="true"
                :draggable="draggable"
                :sst="true"
                :pagination="draggable ? null : pagination"
                @action="action"
                @filter="filter"
                @sort="sort"
            />
        </div>
    </div>
</template>

<script>
import Datatable from '@/modules/Shared/Components/table/SKDataTable.vue'
import loader from "@/modules/Shared/Mixins/loader";
import DatatableService from '@/modules/Shared/Pages/sections/DatatableService';

export default {
    name: 'ListPage',
    mixins: [loader],
    components: {
        Datatable
    },
    props: {
        datatableConfig: Array,
        elementsAPIURL: String,
        commonFilters: Object,
        perPage: {
            type: Number,
            default: 30
        },
        draggable: {
            type: Boolean,
            default: false
        },
        actions: {
            type: Array,
            default: () => ['create', 'edit', 'delete']
        },
        routeConfigs: {
            type: Object,
        }
    },
    data () {
        return {
            zones: [],
            pagination: {},
            service: null,
        }
    },
    computed: {
        dataTableActions() {
            return this.actions.filter(action => action !== 'create')
        }
    },
    async created() {
        this.service = new DatatableService(this.elementsAPIURL, this.commonFilters, this.perPage)
        await this.getElements({}, 1)
    },
    methods: {
        async filter(parameters)
        {
            let filters = {}
            parameters.filters.forEach(function(item) { filters[item.field] = item.value });
            this.getElements(filters, parameters.page)
        },
        async sort(sortedElements)
        {
            await this.load(async () => {
                await this.service.sortElements(sortedElements)
            })
        },
        async getElements(filters, page) 
        {
            await this.load(async () => {
                let response = await this.service.fetchElements(filters, page)
                this.zones = response.data.map(
                    zone => ({...zone, 'actions': this.dataTableActions})
                )
                this.pagination = {
                    per_page: response.per_page,
                    total: response.total
                }
            })
        },
        action(type, uuid) {
            switch(type) {
                case 'edit':
                    this.$router.push({
                        name: `${this.routeConfigs.prefix}-edit`,
                        params: {
                            'elementUuid': uuid
                        }
                    });
                break;
                case 'delete':
                    this.deleteZone(uuid)
                break;
            }
        },
        async deleteZone(uuid) {
            await this.loadAndNotify(async () => {
                await this.service.deleteElement(uuid)
                await this.getElements({}, 1)
            })
        }
    }
}
</script>